import { useRef, useState, useEffect } from 'react';
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {roomDesignActions} from '../reducers/roomDesign'
import {selectCanChangePageSetting, selectCanAddFile, selectCanReloadRoomDesign, selectBeforeImageData, selectAfterImageData, selectAfterImageView} from '../selectors'
import SiteNoti from './SiteNoti'
import ExamplePhotoNG from './ExamplePhotoNG'
import RoomDesignEditRoomTypeLifestyle from './RoomDesignEditRoomTypeLifestyle'
import RoomDesignEditActionButtons from './RoomDesignEditActionButtons'
import {ReloadRoomDesignIcon, DelPhotoIcon, AddMorePhotoIcon, LoadingIcon,
  AfterImageDownloadIcon, AfterImageBackwardIcon, AfterImageForwardIcon,
  LeadPencilIcon} from './SVGIcons'

function mapStateToProps(state: any) {
  const canChangePageSetting = selectCanChangePageSetting(state)
  const canAddFile = selectCanAddFile(state)
  const canReloadRoomDesign = selectCanReloadRoomDesign(state)
  const selectedBeforeImageData = selectBeforeImageData(state)
  const afterImageView = selectAfterImageView(state)
  const {selectedAfterImages, selectedAfterImageData, selectedAfterImageIndex} = selectAfterImageData(state)
  return { ...state.roomDesign, 
    canChangePageSetting, 
    canAddFile, 
    canReloadRoomDesign, 
    selectedBeforeImageData, 
    selectedAfterImages, 
    selectedAfterImageData, 
    selectedAfterImageIndex,
    afterImageView
  }
}

const RoomDesignEditFormSome = (props: any) => {
  let {
    beforeImages,
    maxExecTimePerImage,
    runTime,
    canChangePageSetting,
    canAddFile,
    canReloadRoomDesign,
    selectedBeforeImageData,
    selectedAfterImages, 
    selectedAfterImageData, 
    selectedAfterImageIndex,
    afterImageView
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  const inputFile = useRef<HTMLInputElement | null>(null);
  const beforeImageRef = useRef<HTMLImageElement | null>(null);
  const afterImageRef = useRef<HTMLImageElement | null>(null);
  const [afterImageLoaded, setAfterImageLoaded] = useState(false);
  useEffect(() => {
    setAfterImageLoaded(false)
  }, [selectedBeforeImageData, selectedAfterImageIndex]);
  
  const maxExecTime = maxExecTimePerImage * beforeImages.length
  const processPercent = runTime < maxExecTime*0.9 ? runTime / maxExecTime : 0.9
  const displayPercent = Math.floor(processPercent * 100) + "%"
  const strokeDashoffset = 100 - 100*processPercent

  const onButtonAddImage = () => {
    if (!inputFile || !inputFile.current) return

    inputFile.current.click();
  }
  const addBeforeImages = (event: any) => {
    if (!canChangePageSetting) return

    dispatch(roomDesignActions.addBeforeImages(event.target.files))
  }
  const handleDragOver = (e: any) => {
    e.preventDefault();
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    if (!canChangePageSetting) return

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFiles = e.dataTransfer.files
      dispatch(roomDesignActions.addBeforeImages(droppedFiles))
    }
  };
  const removeBeforeImage = (stubId: number) => {
    if (!canChangePageSetting) return
    
    dispatch(roomDesignActions.removeBeforeImage(stubId))
  }
  const setSelectedBeforeImage = (stubId: number) => {
    dispatch(roomDesignActions.setSelectedBeforeImage(stubId))
  }
  const setSelectedAfterImage = (id: number) => {
    dispatch(roomDesignActions.setSelectedAfterImage(id))
  }
  const selectPrevAfterImage = () => {
    if (selectedAfterImages[selectedAfterImageIndex-1])
      dispatch(roomDesignActions.setSelectedAfterImage(selectedAfterImages[selectedAfterImageIndex-1].id))
  }
  const selectNextAfterImage = () => {
    if (selectedAfterImages[selectedAfterImageIndex+1])
      dispatch(roomDesignActions.setSelectedAfterImage(selectedAfterImages[selectedAfterImageIndex+1].id))
  }
  const editImage = () => {
    if (beforeImageRef && afterImageRef) {
      dispatch(roomDesignActions.setBeforeImageRef(beforeImageRef.current))
      dispatch(roomDesignActions.setAfterImageRef(afterImageRef.current))
      dispatch(roomDesignActions.setIsEditPhotoPopupShow(true))
    }
  }
  const reloadPage = () => {
    if(!canReloadRoomDesign) return

    dispatch(roomDesignActions.setInitState())
  }

  return (
    <>
      <div className="max-w-8xl w-full mx-auto py-3 px-4 sm:px-6 md:px-8 text-[#2d2d2d]">
        <SiteNoti />

        <div className="grid grid-cols-9 gap-2">
          <div className="col-1 bg-gray-200 overflow-y-auto rounded min-h-[30.28rem] flex flex-col p-2">
            <button type="button" onClick={reloadPage} disabled={!canReloadRoomDesign}
              className={canReloadRoomDesign 
                ? "bg-gray-50 flex p-2 rounded-md border border-yellow-50 mb-4 text-sm text-yellow-700 items-center justify-center"
                : "bg-gray-300 flex p-2 rounded-md mb-4 text-sm items-center justify-center"
              }>
              <ReloadRoomDesignIcon />一括削除
            </button>
            <ul className="space-y-2">
              {beforeImages.map((beforeImage: any) => (
                <li key={beforeImage.stubId} onClick={e => setSelectedBeforeImage(beforeImage.stubId)}
                  className={beforeImage.stubId == selectedBeforeImageData.stubId 
                    ? "flex items-center justify-center w-full bg-gray-300 h-24 w-28 overflow-hidden mx-auto rounded-sm cursor-pointer relative ring-offset-2 ring-2 ring-blue-500"
                    : "flex items-center justify-center w-full bg-gray-300 h-24 w-28 overflow-hidden mx-auto rounded-sm cursor-pointer relative"}>
                  {beforeImage.roomType && beforeImage.lifestyle && (
                    <span className="bg-[#ff4600cc] absolute top-1 left-1 text-xs text-white rounded-lg px-1">選択済</span>
                  )}
                  {canChangePageSetting && (
                    <button type="button" onClick={e => removeBeforeImage(beforeImage.stubId)} className="absolute top-1 right-1 opacity-50 hover:opacity-70">
                      <DelPhotoIcon />
                    </button>
                  )}
                  <img src={beforeImage.uri} className="block w-full" alt=""/>
                </li>
              ))}

              {canAddFile && (
                <li className="text-center">
                  <label>
                    <button type="button" onClick={onButtonAddImage} className="text-blue-600 hover:text-blue-600 rounded-full hover:bg-gray-100">
                      <AddMorePhotoIcon />
                    </button>
                    <input type="file" ref={inputFile} multiple accept="image/png, image/jpeg, image/webp" onChange={addBeforeImages} className="hidden" />
                  </label>
                </li>
              )}
            </ul>
          </div>

          <div
            className="grid grid-cols-2 col-span-8 border border-gray-300 bg-white overflow-y-auto rounded">
            <div className="flex flex-col w-full col-1 flex-nowrap p-3 bg-white">
              <RoomDesignEditRoomTypeLifestyle />
              <div
                className="flex flex-col items-center justify-center h-full rounded">
                {selectedBeforeImageData && canAddFile && (
                  <label onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
                    <img src={selectedBeforeImageData.uri} className="block w-full" alt=""/>
                    <input type="file" multiple accept="image/png, image/jpeg, image/webp" onChange={addBeforeImages} className="hidden" />
                  </label>
                )}
                {selectedBeforeImageData && !canAddFile && (
                  <img ref={beforeImageRef} src={selectedBeforeImageData.uri} className="block w-full" alt=""/>
                )}
              </div>
            </div>
            
            {afterImageView == 'show_blank' && (
              <ExamplePhotoNG />
            )}
            {afterImageView == 'show_gen_image_error' && (
              <div className="p-3 col-1 border-s border-gray-300 flex">
                <div className="relative m-auto text-red-600 text-center text-lg">
                  画像生成に失敗しました。<br />
                  再度画像生成を行ってください。<br />
                  ※クレジットは消費されておりません。
                </div>
              </div>
            )}
            {afterImageView == 'show_images' && (
              <div className="p-3 col-1 border-s border-gray-300 flex">
                <div className="relative w-full after_image_region flex flex-col justify-between">
                  <div className="flex mb-2 mt-10 relative z-30 space-x-1">
                    {selectedAfterImages.map((image: any, index: number) => (
                      <button key={image.id} onClick={() => setSelectedAfterImage(image.id)} className={selectedAfterImageData && selectedAfterImageData.id == image.id
                        ? "min-w-6 min-h-6 submit-btn text-white hover:text-gray-400 rounded"
                        : "min-w-6 min-h-6 bg-gray-800 text-white hover:text-gray-400 rounded"
                      }>{index+1}</button>
                    ))}
                  </div>
                  <div className="relative group">
                    {selectedAfterImageData && (
                      <div className="duration-100 ">
                        <div className='absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-30 flex items-center gap-8'>
                          <button onClick={editImage} className='w-[72px] h-[72px] rounded-full bg-white/95 hover:bg-white/90 hidden group-hover:flex-col justify-center items-center z-30'>
                            <LeadPencilIcon />
                            <p className='text-sm text-center'>編集</p>
                          </button>
                          <a href={selectedAfterImageData.serverURI} download="" target="_blank" className="w-[72px] h-[72px] rounded-full bg-white/95 hover:bg-white/90 hidden group-hover:flex-col justify-center items-center z-30">
                            <AfterImageDownloadIcon />
                            <p className='text-sm text-center'>保存</p>
                          </a>
                        </div>
                        <span className="absolute group-hover:inset-0 group-hover:bg-gray-500/60 z-10"></span>
                          <img ref={afterImageRef} src={selectedAfterImageData.serverURI} style={{display: afterImageLoaded ? 'block': 'none'}} className="block w-full" alt="" 
                            onLoad={()=>{setAfterImageLoaded(true)}} crossOrigin={'anonymous'}/>
                      </div>
                    )}
                    {selectedAfterImages[selectedAfterImageIndex-1] && (
                      <button onClick={selectPrevAfterImage}
                        className="absolute -translate-y-1/2 top-1/2 start-0 z-30 flex px-4 cursor-pointer focus:outline-none hidden group-hover:block">
                        <span
                          className="inline-flex items-center justify-center w-10 h-10 rounded-lg bg-white/95 hover:bg-white/90 group-focus:ring-4 group-focus:ring-white group-focus:outline-none border border-gray-400">
                          <AfterImageBackwardIcon />
                          <span className="sr-only">前へ</span>
                        </span>
                      </button>
                    )}
                    {selectedAfterImages[selectedAfterImageIndex+1] && (
                      <button onClick={selectNextAfterImage}
                        className="absolute -translate-y-1/2 top-1/2 end-0 z-30 flex px-4 cursor-pointer focus:outline-none hidden group-hover:block">
                        <span
                          className="inline-flex items-center justify-center w-10 h-10 rounded-lg bg-white/95 hover:bg-white/90 group-focus:ring-4 group-focus:ring-white group-focus:outline-none border border-gray-400">
                          <AfterImageForwardIcon />
                          <span className="sr-only">次へ</span>
                        </span>
                      </button>
                    )}
                  </div>

                  {!afterImageLoaded && (
                    <LoadingIcon className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" />
                  )}
                </div>
              </div>
            )}
            {afterImageView == 'show_loading' && (
              <div className="p-3 col-1 border-s border-gray-300 flex">
                <div className="relative size-40 m-auto">
                  <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200"
                      strokeWidth="2"></circle>
                    <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-blue-600"
                      strokeWidth="2" strokeDasharray="100" strokeDashoffset={strokeDashoffset} strokeLinecap="round"></circle>
                  </svg>
                  <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                    <span className="text-center text-2xl font-bold text-blue-600">{displayPercent}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <RoomDesignEditActionButtons />
      </div>
    </>
  );
};

export default connect(mapStateToProps)(RoomDesignEditFormSome)