import {MaxPhotoUpload} from '../constants'

export function selectCanCreateRoomDesign(state: any) {
    const {currentUser, roomDesign} = state
    const {isLoading, beforeImages} = roomDesign
    if (isLoading || beforeImages.length == 0) return false

    const {creditConsumed, creditLimit} = currentUser
    if (creditConsumed + beforeImages.length > creditLimit) return false

    const nonOKImages = beforeImages.filter((image: any) => !image.roomType || !image.lifestyle)
    if (nonOKImages.length > 0) return false

    const hasId = beforeImages.find((image: any) => image.id)
    if (hasId) return false

    return true
}

export function selectCanRegenAfterImages(state: any) {
    const {roomDesign} = state
    const {isLoading} = roomDesign
    if (isLoading) return false

    const selectedBeforeImageData = selectBeforeImageData(state)
    const {id, maxRegenCount} = selectedBeforeImageData
    if (!id) return false
    if (maxRegenCount == 0) return false

    return true
}

export function selectCanChangePageSetting(state: any) {
    const {roomDesign} = state
    const {isLoading, beforeImages} = roomDesign
    if (isLoading) return false

    const hasId = beforeImages.find((image: any) => image.id)
    if (hasId) return false

    return true
}

export function selectCanAddFile(state: any) {
    const {roomDesign} = state
    const {isLoading, beforeImages} = roomDesign
    if (isLoading) return false
    if (beforeImages.length >= MaxPhotoUpload) return false

    const hasId = beforeImages.find((image: any) => image.id)
    if (hasId) return false

    return true
}

export function selectCanReloadRoomDesign(state: any) {
    const {roomDesign} = state
    const {isLoading, beforeImages} = roomDesign
    if (isLoading) return false

    const hasProcessedImage = beforeImages.find((image: any) => image.id)
    return !hasProcessedImage
}

export function selectCanConfirmReloadRoomDesign(state: any) {
    const {roomDesign} = state
    const {isLoading, beforeImages} = roomDesign
    if (isLoading) return false

    const hasProcessedImage = beforeImages.find((image: any) => image.id || image.isGeneratedFail)
    return hasProcessedImage
}

export function selectAfterImageView(state: any) {
    const {roomDesign} = state
    const {isLoading, selectedRegenImage} = roomDesign
    const selectedBeforeImageData = selectBeforeImageData(state)
    const {id, isGeneratedFail} = selectedBeforeImageData ? selectedBeforeImageData : {}
    const {selectedAfterImages, selectedAfterImageData} = selectAfterImageData(state)
    if (isLoading && (!selectedRegenImage || selectedBeforeImageData.stubId == selectedRegenImage) && selectedAfterImages.length == 0)
        return 'show_loading'

    if (isGeneratedFail && (!selectedRegenImage || selectedBeforeImageData.stubId != selectedRegenImage) && selectedAfterImages.length == 0)
        return 'show_gen_image_error'

    if (selectedAfterImageData)
        return 'show_images'

    return 'show_blank'
}

export function selectBeforeImageData(state: any) {
    const {roomDesign} = state
    const {selectedBeforeImage, beforeImages} = roomDesign
    const existBeforeImageData = beforeImages.find((image: any) => image.stubId == selectedBeforeImage)
    const selectedBeforeImageData = existBeforeImageData ? existBeforeImageData : beforeImages[0]
    return selectedBeforeImageData ? selectedBeforeImageData : {}
}

export function selectAfterImageData(state: any) {
    const {roomDesign} = state
    const {afterImages, selectedAfterImage} = roomDesign
    const selectedBeforeImageData = selectBeforeImageData(state)
    const selectedAfterImages = afterImages && selectedBeforeImageData 
        ? afterImages.filter((image: any) => image.before_image_id == selectedBeforeImageData.id) : []
    const findAfterImageData = selectedAfterImages.find((image: any) => image.id == selectedAfterImage)
    const selectedAfterImageData = findAfterImageData ? findAfterImageData : selectedAfterImages[0]
    const selectedAfterImageIndex = selectedAfterImages.findIndex((image: any) => image.id == selectedAfterImageData.id)
    return {selectedAfterImages, selectedAfterImageData, selectedAfterImageIndex}
}