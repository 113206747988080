import { useState, useEffect } from 'react'
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useSelector, useDispatch} from 'react-redux'
import {selectBeforeImageData} from '../../selectors'
import {editPhotoActions, Line as LineInterface} from '../../reducers/editPhoto'
import {useRef} from "react";
import { Stage, Layer, Line, Image as ImageKonva } from "react-konva";

const cursorStyle = (curHead: string, curHeadSize: number) => {
    switch (curHead) {
      case "circle":
        return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${curHeadSize}" height="${curHeadSize}" viewBox="0 0 ${curHeadSize} ${curHeadSize}"><circle cx="${curHeadSize / 2}" cy="${curHeadSize / 2}" r="${
          curHeadSize / 2 - 1
        }" fill="none" stroke="white" stroke-width="2" /></svg>') ${curHeadSize / 2} ${curHeadSize / 2}, auto`;
      case "triangle":
        return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${curHeadSize}" height="${curHeadSize}" viewBox="0 0 ${curHeadSize} ${curHeadSize}"><polygon points="1,${curHeadSize - 1} ${
          curHeadSize / 2
        },1 ${curHeadSize - 1},${curHeadSize - 1}" fill="none" stroke="white" stroke-width="2" /></svg>') ${curHeadSize / 2} ${curHeadSize / 2}, auto`;
      case "square":
        return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${curHeadSize}" height="${curHeadSize}" viewBox="0 0 ${curHeadSize} ${curHeadSize}"><rect x="1" y="1" width="${
          curHeadSize - 2
        }" height="${curHeadSize - 2}" fill="none" stroke="white" stroke-width="2" /></svg>') ${curHeadSize / 2} ${curHeadSize / 2}, auto`;
      default:
        return "default";
    }
};

const Drawing = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const stageWrapRef = useRef<any>(null);
    const stageRef = useRef<any>(null);
    const outputStageRef = useRef<any>(null);
    const previewCanvasRef = useRef<any>(null);
    const isDrawing = useRef(false);
    const [stageWidth, setStageWidth] = useState(0)
    const [stageHeight, setStageHeight] = useState(0)
    const [outputStageWidth, setOutputStageWidth] = useState(0)
    const [outputSHeight, setOutputStageHeight] = useState(0)
    const {lines, curHead, curHeadSize, stage, outputStage, previewCanvas}  = useSelector((state: any) => state.editPhoto)
    const {beforeImageRef, afterImageRef}  = useSelector((state: any) => state.roomDesign)
    const selectedBeforeImageData =  useSelector((state: any) => selectBeforeImageData(state))
    useEffect(() => {
        setStageWidth(stageWrapRef.current.clientWidth)
        setStageHeight(window.innerHeight)
        const newStage = stageRef.current.getStage()
        if (!stage)
            dispatch(editPhotoActions.setStage(newStage))
        const newOutputStage = outputStageRef.current.getStage()
        if (!outputStage)
            dispatch(editPhotoActions.setOutputStage(newOutputStage))
        const newPreviewCanvas = previewCanvasRef.current
        if (!previewCanvas)
            dispatch(editPhotoActions.setPreviewCanvas(newPreviewCanvas))
    }, [])
    useEffect(() => {
        setTimeout(() => dispatch(editPhotoActions.drawPreviewImage()), 1)
        setTimeout(() => dispatch(editPhotoActions.drawPreviewImage()), 100)
    }, [lines, afterImageRef])
    useEffect(() => {
        setStageHeight(afterImageRef.height * (stageWrapRef.current.clientWidth / afterImageRef.width))
        setOutputStageWidth(afterImageRef.naturalWidth)
        setOutputStageHeight(afterImageRef.naturalHeight)
    }, [selectedBeforeImageData])

    const handleMouseDown = (e: any) => {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        dispatch(editPhotoActions.setLines([...lines, {head: curHead, headSize: curHeadSize, points: [pos.x, pos.y, pos.x, pos.y] }]))
    };

    const handleMouseMove = (e: any) => {
        // no drawing - skipping
        if (!isDrawing.current) {
        return;
        }
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        dispatch(editPhotoActions.addPoinToLastLine({x: point.x, y: point.y}))
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
    };

    const changeCursor = () => {
        stageWrapRef.current.style.cursor = cursorStyle(curHead, curHeadSize);
    }

    return (
        <div ref={stageWrapRef} onMouseOver={changeCursor} className='relative'>
            <canvas 
                ref={previewCanvasRef}
                width={stageWidth}
                height={stageHeight}
            ></canvas>
            <Stage
                width={stageWidth}
                height={stageHeight}
                className='absolute top-0'
                onMouseDown={handleMouseDown}
                onMousemove={handleMouseMove}
                onMouseup={handleMouseUp}
            >
            </Stage>
            <Stage
                ref={stageRef}
                width={stageWidth}
                height={stageHeight}
                className='hidden'
                onMouseDown={handleMouseDown}
                onMousemove={handleMouseMove}
                onMouseup={handleMouseUp}
            >
                <Layer>
                    {lines.map((line: LineInterface, i: number) => (
                    <Line
                        key={i}
                        points={line.points}
                        stroke="black"
                        strokeWidth={line.headSize}
                        tension={1}
                        opacity={1}
                        lineCap="round"
                    />
                    ))}
                </Layer>
                <Layer>
                    <ImageKonva image={beforeImageRef} width={stageWidth} height={stageHeight} />
                </Layer>
                <Layer>
                    <ImageKonva image={afterImageRef} width={stageWidth} height={stageHeight} />
                </Layer>
                <Layer></Layer>
            </Stage>
            <Stage
                ref={outputStageRef}
                width={outputStageWidth}
                height={outputSHeight}
                className='hidden'
            >
                <Layer></Layer>
                <Layer>
                    <ImageKonva image={beforeImageRef} width={outputStageWidth} height={outputSHeight} />
                </Layer>
                <Layer>
                    <ImageKonva image={afterImageRef} width={outputStageWidth} height={outputSHeight} />
                </Layer>
                <Layer></Layer>
            </Stage>
        </div>
    );
};

export default Drawing
