
export const PlanType = {
    "Basic": 2,
    "Pro": 1,
    "Trial": 3,
    "Custom": 4,
    "Elite": 5,
    "Lite":6 
}

export const EditablePlanTypes = ['4']

export const PlanTypeRealEstateType: any = {
    "Rental": {
        "Basic": 2,
        "Pro": 1,
        "Elite": 5,
        "Custom": 4,
    },
    "Distribution": {
        "Lite": 6,
        "Basic": 2,
        "Pro": 1,
        "Elite": 5,
        "Custom": 4
    },
    "NewlyConstructedDetachedHouse": {
        "Basic": 2,
        "Pro": 1,
        "Elite": 5,
        "Custom": 4,
    }
}

export const PlanTypeLimits: any = {
    "Rental": {
        "Basic": "100",
        "Pro": "300",
        "Elite": "500",
        "Custom": ""
    },
    "Distribution": {
        "Lite": "50",
        "Basic": "100",
        "Pro": "300",
        "Elite": "500",
        "Custom": ""
    },
    "NewlyConstructedDetachedHouse": {
        "Basic": "50",
        "Pro": "150",
        "Elite": "300",
        "Custom": "",
    }
}

export const RealEstateType = {
    "賃貸": "Rental",
    "売買": "Distribution",
    "新築戸建": "NewlyConstructedDetachedHouse"
}

export const PREmailAvailType = {
    "OK": "OK",
    "NG": "NG",
}

export const IsActive = {
    "利用中": 1,
    "停止": 0,
}

export const RoomType = {
    "リビング": "living",
    "ダイニング": "dining",
    "ベッドルーム（ワンルーム）": "bedroom",
}

export const LifestyleType = {
    "スタンダード": "standard",
    "モダン": "modern",
    "ノルディック": "scandinavian",
    "インダストリアル": "industrial",
    "ラグジュアリー": "luxury",
}

export const MaxPhotoUpload = 5
export const MaxUploadFileSize = 5*1024*1024
export const MaxRegenCount = 5