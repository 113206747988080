import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {toastActions} from './toast'
import {drawPreviewImage as doDrawPreviewImage} from '../libs/edit_after_photo'
export interface Line {
    head: 'circle' | 'triangle' | 'square'
    headSize: number
    points: number[]
}

const DefaultHeadSize = 50

const initialState = {
    lines: [] as Line[],
    curHead: 'circle' as 'circle' | 'triangle' | 'square',
    curHeadSize: DefaultHeadSize,
    stage: undefined as any,
    outputStage: undefined as any,
    previewCanvas: undefined as any,
}

const drawPreviewImage = createAsyncThunk(
    'drawPreviewImage',
    async (_, thunkAPI: any) => {
        const {stage, previewCanvas} = thunkAPI.getState().editPhoto
        doDrawPreviewImage(stage, previewCanvas)
    }
)

const editPhotoSlice = createSlice({
    name: 'editPhoto',
    initialState,
    reducers: {
        setDefaultCurHead: (state, action) => {
            state.curHead = action.payload
            state.curHeadSize = DefaultHeadSize
        },
        setCurHead: (state, action) => {
            state.curHead = action.payload
        },
        setCurHeadSize: (state, action) => {
            state.curHeadSize = action.payload
        },
        setStage: (state, action) => {
            state.stage = action.payload
        },
        setOutputStage: (state, action) => {
            state.outputStage = action.payload
        },
        setPreviewCanvas: (state, action) => {
            state.previewCanvas = action.payload
        },
        setLines: (state, action) => {
            state.lines = action.payload
        },
        addPoinToLastLine: (state, action) => {
            const {x, y} = action.payload
            let lastLine = state.lines[state.lines.length - 1];
            // add point
            lastLine.points = lastLine.points.concat([x, y]);
            state.lines.splice(state.lines.length - 1, 1, lastLine);
        },
        setInitState: (state) => {
            return initialState
        },
    },
})

const editPhotoActions = {
    ...editPhotoSlice.actions,
    drawPreviewImage,
}
export {editPhotoActions}
export default editPhotoSlice.reducer