import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {roomDesignActions} from '../reducers/roomDesign'
import {selectCanCreateRoomDesign, selectCanChangePageSetting} from '../selectors'
import SiteNoti from './SiteNoti'
import ExamplePhotoNG from './ExamplePhotoNG'
import {BlankPhotoIcon, SelectFileIcon, GenerateAfterPhotoIcon} from './SVGIcons'

function mapStateToProps(state: any) {
  const canCreateRoomDesign = selectCanCreateRoomDesign(state)
  const canChangePageSetting = selectCanChangePageSetting(state)
  return { ...state.roomDesign, canCreateRoomDesign, canChangePageSetting }
}

const RoomDesignEditFormEmpty = (props: any) => {
  const {
    canChangePageSetting,
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  const addBeforeImages = (event: any) => {
    if (!canChangePageSetting) return

    dispatch(roomDesignActions.addBeforeImages(event.target.files))
  }

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    if (!canChangePageSetting) return

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFiles = e.dataTransfer.files
      dispatch(roomDesignActions.addBeforeImages(droppedFiles))
    }
  };

  return (
    <>
      <div className="max-w-8xl w-full mx-auto py-3 px-4 sm:px-6 md:px-8 text-[#2d2d2d]">
        <SiteNoti />

        <div className="grid grid-cols-9 gap-2">
          <div className="col-1 bg-gray-200 overflow-y-auto rounded min-h-[30.28rem] flex flex-col p-2">
            <ul className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <li key={index}
                  className="flex items-center justify-center w-full bg-gray-300 h-24 w-28 overflow-hidden mx-auto rounded-sm">
                  <BlankPhotoIcon />
                </li>
              ))}
            </ul>
          </div>
          
          <div className="grid grid-cols-2 col-span-8 border border-gray-300 bg-gray-100 overflow-y-auto rounded">
            <div className="flex flex-col w-full col-1 flex-nowrap p-3 bg-white">
              <label onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} className="flex flex-col items-center justify-center h-full max-h-[33rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                <div className="flex flex-col items-center justify-center pt-5 pb-6 space-y-3">
                  <SelectFileIcon />
                  <p className="mb-2 text-sm text-gray-500"><strong
                    className="font-semibold text-blue-600">画像を選択、またはドラッグ＆ドロップ</strong></p>
                  <p className="mb-2 text-sm text-gray-500"><strong
                    className="font-semibold text-blue-600">最大5枚まで入力可能です</strong></p>
                  <p className="text-xs text-gray-500">image/jpeg, image/png, image/webpが使用可能（5MBまで）</p>
                  <div
                    className="px-3 py-2 focus:ring-blue-500 focus:border-blue-500 whitespace-nowrap text-sm font-medium rounded-md text-white hover:text-white bg-blue-600 hover:bg-blue-700 w-auto min-w-28 text-center">
                    画像を選択</div>
                </div>
                <input type="file" multiple accept="image/png, image/jpeg, image/webp" onChange={addBeforeImages} className="hidden" />
              </label>
            </div>

            <ExamplePhotoNG />
          </div>
        </div>
        <div className="grid grid-cols-9 gap-4 mt-3">
          <div className="col-span-4 col-start-2 space-y-2">
            <button type="button"
              className="px-5 py-2.5 flex items-center gap-1 rounded-full mx-auto text-sm text-gray-900 bg-gray-400 disabled:opacity-75"
              disabled>
              <GenerateAfterPhotoIcon />
              バーチャルホームステージング生成
            </button>
          </div>
        </div>

      </div>
    </>
  );
};

export default connect(mapStateToProps)(RoomDesignEditFormEmpty)