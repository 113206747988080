import {ThunkDispatch} from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import {connect, useDispatch } from "react-redux"
import { useEffect } from "react";
import CustomerNavBar from './CustomerNavBar'
import Toast from './Toast'
import RoomDesignEditForm from './RoomDesignEditForm'
import SelectRoomType from './SelectRoomType'
import SelectLifestyle from './SelectLifestyle'
import CreateRoomDesignConfirm from './CreateRoomDesignConfirm'
import RegenRoomDesignConfirm from './RegenRoomDesignConfirm'
import RoomDesignConfirmClearAll from './RoomDesignConfirmClearAll'
import RoomDesignPhotoTooBigPopup from './RoomDesignPhotoTooBigPopup'
import {siteNotiActions} from '../reducers/siteNoti'
import {roomDesignActions} from '../reducers/roomDesign'
import RoomDesignEditPhotoPopup from "./edit_after_photo/RoomDesignEditPhotoPopup";
import RoomDesignSaveChangesConfirm from "./edit_after_photo/RoomDesignSaveChangesConfirm";
import RoomDesignDiscardChangesConfirm from "./edit_after_photo/RoomDesignDiscardChangesConfirm";

function mapStateToProps(state: any) {
  return {}
}

const NewRoomDesign = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  useEffect(() => {
    dispatch(roomDesignActions.setInitState())
    dispatch(siteNotiActions.getSiteNoti())
  }, []);
  return (
    <>
      <Toast />
      <CustomerNavBar />
      <RoomDesignEditForm />
      <SelectRoomType />
      <SelectLifestyle />
      <CreateRoomDesignConfirm />
      <RegenRoomDesignConfirm />
      <RoomDesignConfirmClearAll />
      <RoomDesignPhotoTooBigPopup />
      <RoomDesignEditPhotoPopup />
      <RoomDesignSaveChangesConfirm />
      <RoomDesignDiscardChangesConfirm />
    </>
  );
};

export default connect(mapStateToProps)(NewRoomDesign)