import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {roomDesignActions} from '../reducers/roomDesign'
import {RoomType, LifestyleType} from '../constants'
import {getKeyByValue} from '../libs'
import {selectCanChangePageSetting, selectBeforeImageData} from '../selectors'
import pho_living from '../../assets/images/pho_living.png'
import pho_dining from '../../assets/images/pho_dining.png'
import pho_bedroom from '../../assets/images/pho_bedroom.png'
import pho_studio from '../../assets/images/pho_studio.png'
import pho_standard from '../../assets/images/pho_standard.webp'
import pho_modern from '../../assets/images/pho_modern.webp'
import pho_nordic from '../../assets/images/pho_nordic.webp'
import pho_industrial from '../../assets/images/pho_industrial.webp'
import pho_luxury from '../../assets/images/pho_luxury.webp'
import {NoticeIcon} from './SVGIcons'

function mapStateToProps(state: any) {
  const canChangePageSetting = selectCanChangePageSetting(state)
  const selectedBeforeImageData = selectBeforeImageData(state)
  return { ...state.roomDesign, canChangePageSetting, selectedBeforeImageData }
}

const RoomDesignEditRoomTypeLifestyle = (props: any) => {
  let {
    canChangePageSetting,
    selectedBeforeImageData
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  if (!selectedBeforeImageData) return null
  const {roomType, lifestyle, hasTatami} = selectedBeforeImageData
  let roomTypeImage
  if (roomType == "living")
    roomTypeImage = pho_living
  if (roomType == "dining")
    roomTypeImage = pho_dining
  if (roomType == "bedroom")
    roomTypeImage = pho_bedroom
  if (roomType == "studio")
    roomTypeImage = pho_studio

  let lifestyleImage
  if (lifestyle == "standard")
    lifestyleImage = pho_standard
  if (lifestyle == "modern")
    lifestyleImage = pho_modern
  if (lifestyle == "scandinavian")
    lifestyleImage = pho_nordic
  if (lifestyle == "industrial")
    lifestyleImage = pho_industrial
  if (lifestyle == "luxury")
    lifestyleImage = pho_luxury
  
  const openRoomTypePopup = () => {
    if(!canChangePageSetting) return

    dispatch(roomDesignActions.setIsRoomTypePopupShow(true))
  }
  const openLifestylePopup = () => {
    if(!canChangePageSetting) return
    
    dispatch(roomDesignActions.setIsLifestylePopupShow(true))
  }
  const setHasTatami = (value: any) => {
    if(!canChangePageSetting) return
    
    dispatch(roomDesignActions.setHasTatami(value))
  }
  const roomTypeTxt: any = roomType ? getKeyByValue(RoomType, roomType) : "未選択"

  return (
    <>
      <span className="mb-2 text-sm text-slate-600">※全ての画像にて、出力設定を行ってください。</span>
      <div className="mb-2 flex flex-wrap gap-3">
        <div className="w-auto flex items-center space-x-2">
          <div className="flex-shrink-1">
            <h3 className="mb-1 text-sm items-center">
              <strong>部屋タイプ</strong>
            </h3>
            <button onClick={openRoomTypePopup} disabled={!canChangePageSetting}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block min-w-36 max-w-auto p-2.5"
              type="button">
              {roomTypeTxt}
            </button>
          </div>
          <div className="w-16 h-16 rounded overflow-hidden">
            <img src={roomTypeImage} className="w-full h-auto object-cover"/>
          </div>
        </div>

        <div className="w-auto flex items-center space-x-2">
          <div className="flex-shrink-1 relative">
            <h3 className="mb-1 text-sm items-center">
              <strong>生活スタイル</strong>
            </h3>
            <button onClick={openLifestylePopup} disabled={!canChangePageSetting}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
              type="button">
              {lifestyle ? getKeyByValue(LifestyleType, lifestyle) : "未選択"}
            </button>
          </div>
          <div className="w-16 h-16 rounded overflow-hidden">
            <img src={lifestyleImage} className="w-full h-auto object-cover"/>
          </div>
        </div>

        <div className="w-auto flex items-center space-x-2">
          <div className="relative">
            <h3 className="mb-1 text-sm items-center flex">
              
              <strong className="mr-2">畳の有無</strong><NoticeIcon />
            </h3>
            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5">
              <label className="mr-2">
                あり：<input type="radio" checked={hasTatami} onChange={() => setHasTatami(true)}/>
              </label>
              <label className="">
                なし：<input type="radio" checked={!hasTatami} onChange={() => setHasTatami(false)}/>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(RoomDesignEditRoomTypeLifestyle)