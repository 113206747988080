export async function drawOutputImage(stage: any, outputStage: any) {
    const layers = outputStage.children
    const drawingRawLayer = stage.children[0]
    const drawingRawImageData = getLayerImageData(drawingRawLayer)
    const drawingLayer = layers[0]
    await drawScaledImageToLayer(drawingLayer, drawingRawImageData)
    const drawingImageData = getLayerImageData(drawingLayer)
    const beforeImageLayer = layers[1]
    const beforeImageData = getLayerImageData(beforeImageLayer)
    const afterImageLayer = layers[2]
    const afterImageData = getLayerImageData(afterImageLayer)
    const outputLayer = layers[3]
    const mergedImageData = mergeImageData(outputLayer, beforeImageData, afterImageData, drawingImageData)
    drawLayerImage(outputLayer, mergedImageData)
}

export function drawPreviewImage(stage: any, previewCanvas: any) {
    if (!stage) return
    const layers = stage.children
    if (!layers || layers.length == 0) return
    const drawingLayer = layers[0]
    const drawingImageData = getLayerImageData(drawingLayer)
    const beforeImageLayer = layers[1]
    const beforeImageData = getLayerImageData(beforeImageLayer)
    const afterImageLayer = layers[2]
    const afterImageData = getLayerImageData(afterImageLayer)
    const outputLayer = layers[3]
    const mergedImageData = mergeImageData(outputLayer, beforeImageData, afterImageData, drawingImageData)
    drawCanvasImage(previewCanvas, mergedImageData)
}

function mergeImageData(outputLayer: any, beforeImageData: any, afterImageData: any, drawingImageData: any) {
    let outputImageData = getLayerBlankImageData(outputLayer)
    for (let i = 0; i < outputImageData.data.length; i += 4) {
        // Modify pixel data
        if (drawingImageData.data[i + 3] == 0) {
            outputImageData.data[i + 0] = afterImageData.data[i + 0]; // R value
            outputImageData.data[i + 1] = afterImageData.data[i + 1]; // G value
            outputImageData.data[i + 2] = afterImageData.data[i + 2]; // B value
            outputImageData.data[i + 3] = afterImageData.data[i + 3]; // A value
        } else {
            outputImageData.data[i + 0] = beforeImageData.data[i + 0]; // R value
            outputImageData.data[i + 1] = beforeImageData.data[i + 1]; // G value
            outputImageData.data[i + 2] = beforeImageData.data[i + 2]; // B value
            outputImageData.data[i + 3] = beforeImageData.data[i + 3]; // A value
        }
    }
    return outputImageData
}

async function drawScaledImageToLayer(layer: any, imageData: any) {
    const canvas = layer.getCanvas()
    const context = canvas.getContext()
    context.imageSmoothingEnabled = false; // keep pixel perfect
    const bitmap = await createImageBitmap(imageData)
    context.drawImage(bitmap, 0, 0, layer.width(), layer.height());
}

function getLayerImageData(layer: any) {
    const canv = layer.getCanvas() 
    const context = canv.getContext()
    return context.getImageData(0, 0, canv.width, canv.height)
}

function getLayerBlankImageData(layer: any) {
    const canv = layer.getCanvas() 
    const context = canv.getContext()
    return context.createImageData(canv.width, canv.height)
}

function drawLayerImage(layer: any, imageData: any) {
    const canvas = layer.getCanvas() 
    const context = canvas.getContext()
    context.putImageData(imageData, 0, 0);
}

async function drawCanvasImage(canvas: any, imageData: any) {
    const context = canvas.getContext('2d')
    const bitmap = await createImageBitmap(imageData)
    context.drawImage(bitmap, 0, 0, canvas.width, canvas.height);
}