import {ThunkDispatch} from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import {connect, useDispatch } from "react-redux"
import { useEffect } from "react";
import NewRoomDesign from './NewRoomDesign'
import UserManage from './UserManage'

function mapStateToProps(state: any) {
  return { ...state.currentUser }
}

const Home = (props: any) => {
  const {is_staff} = props
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()

  if (!is_staff) return (
    <NewRoomDesign />
  )
  return (
    <UserManage />
  )
};

export default connect(mapStateToProps)(Home)