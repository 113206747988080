import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {PlanType, PlanTypeRealEstateType, PlanTypeLimits} from "../constants"
import {UsersService} from '../api'
import {toastActions} from './toast'
import {checkPostCodeFormat, calPlanLimits} from '../libs/index'
const getEditUser = createAsyncThunk(
    'getEditUser',
    async (props: {id: number, navigate: any}, thunkAPI: any) => {
        const {id, navigate} = props
        const res = await UsersService.usersRetrieve({id})
        if (!res.id) {
            thunkAPI.dispatch(toastActions.flashError(`ユーザーが登録されていません。`))//User doesn't exist.
            navigate("/")
            return
        }

        const editingUser = apiUserToEditingUser(res)
        thunkAPI.dispatch(editingUserActions.setEditingUser(editingUser))
    }
)

function apiUserToEditingUser(user: any) {
    const {id, email, user_name, account} = user
    return {
        id,
        email,
        userName: user_name,
        realEstateType: account.real_estate_type,
        planType: account.plan_type_id,
        creditLimit: account.credit_limit,
        corporateName: account.corporate_name,
        storeName: account.store_name,
        picName: account.person_in_charge_name,
        postCode: account.post_code,
        address: account.address,
        contractStart: account.contract_start_date,
        contractEnd: account.contract_end_date,
        prEmailAvail: account.promotional_email_avail,
        registrationRoute: account.registration_route,
        marketingChannel: account.marketing_channel,
        remarks: account.remarks
    }
}

const submitForm = createAsyncThunk(
    'submitForm',
    async (navigate: any, thunkAPI: any) => {
        const {
            editingPage,
            id,
            email,
            userName,
            password,
            realEstateType,
            planType,
            creditLimit,
            corporateName,
            storeName,
            picName,
            postCode,
            address,
            contractStart,
            contractEnd,
            prEmailAvail,
            registrationRoute,
            marketingChannel,
            remarks,
            } = thunkAPI.getState().editingUser
        
        thunkAPI.dispatch(editingUserActions.setInitError())
       
        let errors = {} as any
        if (!corporateName)
            errors["corporateName"] = "法人名を入力してください。" //Corporate Name is missing
        if (!storeName)
            errors["storeName"] = "店舗名を入力してください。"//Store Name is missing
        if (!checkPostCodeFormat(postCode))
            errors["postCode"] = "郵便番号が無効です。"//Postal Code is invalid
        if (!email)
            errors["email"] = "メールアドレスを入力してください。"//Email is missing
        if (email && !isValidEmail(email))
            errors["email"] = "メールアドレスが無効です。"//Email is invalid
        if (!userName)
            errors["userName"] = "ユーザーIDを入力してください。"//Username is missing
        if (editingPage == 'create' && !password)
            errors["password"] = "パスワードを入力してください。"//Password is missing
        if (!realEstateType)
            errors["realEstateType"] = "不動産種別を入力してください。"//Real Estate Type is missing
        if (!planType)
            errors["planType"] = "利用プランを入力してください。"//Plan Type is missing
        if (!creditLimit)
            errors["creditLimit"] = "上限回数を入力してください。"//Credit Limit is missing
        if (!contractStart)
            errors["contractStart"] = "契約開始日を入力してください。"//Contract Start Date is missing
        if (!prEmailAvail)
            errors["prEmailAvail"] = "販促メールを入力してください。"//Promotional Email Option is missing
        if (Object.keys(errors).length != 0) {
            thunkAPI.dispatch(editingUserActions.setErrors(errors))
            return
        }
        if (editingPage == 'create') {
            thunkAPI.dispatch(editingUserActions.postCreateForm(navigate))
            return
        }
        if (editingPage == 'update') {
            thunkAPI.dispatch(editingUserActions.postUpdateForm(navigate))
            return
        }            
    }
)

const postCreateForm = createAsyncThunk(
    'postCreateForm',
    async (navigate: any, thunkAPI: any) => {
        const APIParams = editingUserToAPIParams(thunkAPI.getState().editingUser)
        const res = await UsersService.usersCreate({requestBody: APIParams})
        if (res.id) {
            thunkAPI.dispatch(toastActions.flashSuccess(`情報が登録されました。`))//User ${res.email} is created
            navigate("/")
            return
        }

        const {msg} = res as any
        if (msg == 'existing_user_name') {
            thunkAPI.dispatch(editingUserActions.setError({"key": "userName", "value": "ユーザーIDは既に存在しています。"}))//Username existed already.
            return
        }

        thunkAPI.dispatch(toastActions.toastError(msg))
    }
)

const postUpdateForm = createAsyncThunk(
    'postUpdateForm',
    async (navigate: any, thunkAPI: any) => {
        const {id} = thunkAPI.getState().editingUser
        const APIParams = editingUserToAPIParams(thunkAPI.getState().editingUser)
        const res = await UsersService.usersUpdate({id, requestBody: APIParams})
        if (res.id) {
            thunkAPI.dispatch(toastActions.flashSuccess(`情報が更新されました。`))//User ${res.email} is updated
            navigate("/")
            return
        }

        const {msg} = res as any
        if (msg == 'existing_user_name') {
            thunkAPI.dispatch(editingUserActions.setError({"key": "userName", "value": "ユーザーIDは既に存在しています。"}))//Username existed already.
            return
        }

        thunkAPI.dispatch(toastActions.toastError(msg))
    }
)

function editingUserToAPIParams(editingUser: any) {
    return {
        id: 0,
        email: editingUser['email'],
        user_name: editingUser['userName'],
        password: editingUser['password'],
        real_estate_type: editingUser['realEstateType'],
        plan_type_id: editingUser['planType'],
        credit_limit: editingUser['creditLimit'],
        corporate_name: editingUser['corporateName'],
        store_name: editingUser['storeName'],
        person_in_charge_name: editingUser['picName'],
        post_code: editingUser['postCode'],
        address: editingUser['address'],
        contract_start_date: editingUser['contractStart'],
        contract_end_date: editingUser['contractEnd'],
        promotional_email_avail: editingUser['prEmailAvail'],
        registration_route: editingUser['registrationRoute'],
        marketing_channel: editingUser['marketingChannel'],
        remarks: editingUser['remarks'],
        created: 'not used',
        modified: 'not used',
    }
}

const calcCreditLimit = createAsyncThunk(
    'calcCreditLimit',
    async (_, thunkAPI: any) => {
        const {
            planType,
            realEstateType,
            } = thunkAPI.getState().editingUser
        const creditLimit = calPlanLimits(realEstateType, planType)
        thunkAPI.dispatch(editingUserActions.setCreditLimit(creditLimit))
    }
)

function isValidEmail(email: string) {
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return reg.test(email)
  }

const initialError = {
    errors: {} as any,
}

const initialState = {
    editingPage: 'create' as 'create' | 'update',
    id: undefined,
    email: '',
    userName: '',
    password: '',
    showPassword: false,
    planType: '',
    creditLimit: '',
    corporateName: '',
    storeName: '',
    picName: '',
    postCode: '',
    address: '',
    contractStart: '',
    contractEnd: '',
    prEmailAvail: '',
    registrationRoute: '',
    marketingChannel: '',
    remarks: '',
    realEstateType: '',
    showHashPassword: true, 
    ...initialError,
}

const editingUserSlice = createSlice({
    name: 'editingUser',
    initialState,
    reducers: {
        setId: (state, action) => {
            state.id = action.payload
        },
        setEditingPage: (state, action) => {
            state.editingPage = action.payload
        },
        setEditingUser: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setUserName: (state, action) => {
            state.userName = action.payload
        },
        setPassword: (state, action) => {
            state.password = action.payload
        },
        switchShowPassword: (state) => {
            state.showPassword = !state.showPassword
        },
        setPlanType: (state, action) => {
            state.planType = action.payload
        },
        setCreditLimit: (state, action) => {
            state.creditLimit = action.payload.replace(/\D/g,'')
        },
        setCorporateName: (state, action) => {
            state.corporateName = action.payload
        },
        setStoreName: (state, action) => {
            state.storeName = action.payload
        },
        setPICName: (state, action) => {
            state.picName = action.payload
        },
        setPostCode: (state, action) => {
            state.postCode = action.payload
        },
        setAddress: (state, action) => {
            state.address = action.payload
        },
        setContractStart: (state, action) => {
            state.contractStart = action.payload
        },
        setContractEnd: (state, action) => {
            state.contractEnd = action.payload
        },
        setPrEmailAvail: (state, action) => {
            state.prEmailAvail = action.payload
        },
        setRegistrationRoute: (state, action) => {
            state.registrationRoute = action.payload
        },
        setMarketingChannel: (state, action) => {
            state.marketingChannel = action.payload
        },
        setRemarks: (state, action) => {
            state.remarks = action.payload
        },
        setRealEstateType: (state, action) => {
            state.realEstateType = action.payload
        },
        setShowHashPassword: (state, action) => {
            state.showHashPassword = action.payload
        },
        setErrors: (state, action) => {
            state.errors = action.payload
        },
        setError: (state, action) => {
            const {key, value} = action.payload as {key: string, value: string}
            state.errors[key] = value
        },
        setInitState: (state) => {
            return initialState
        },
        setInitError: (state) => {
            return {
                ...state,
                ...initialError,
            }
        }
    },
})

const editingUserActions = {
    ...editingUserSlice.actions,
    calcCreditLimit,
    getEditUser,
    submitForm,
    postCreateForm,
    postUpdateForm,
}
export {editingUserActions}
export default editingUserSlice.reducer