import { useEffect } from "react"
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch, useSelector } from "react-redux"
import {useNavigate} from 'react-router-dom'
import {drawOutputImage} from '../../libs/edit_after_photo'
import {selectAfterImageData} from '../../selectors'
import { Modal } from "flowbite-react";
import {CloseModalIcon, LeadPencilIcon} from '../SVGIcons'
import {roomDesignActions} from '../../reducers/roomDesign'

function mapStateToProps(state: any) {
  const {selectedAfterImageData} = selectAfterImageData(state);
  return { ...state.roomDesign, selectedAfterImageData }
}

async function download(url: string, fileName: string) {
  const a = document.createElement("a");
  a.href = url
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

const RoomDesignSaveChangesConfirm = (props: any) => {
  const {
    isSaveChangesPhotoPopupShow,
    selectedAfterImageData
  } = props
  const {stage, outputStage}  = useSelector((state: any) => state.editPhoto)
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  const closePopup = () => {
    dispatch(roomDesignActions.setIsSaveChangesPhotoPopupShow(false))
  }
  const downloadPhoto = () => {
    drawOutputImage(stage, outputStage)
    setTimeout(() => {
      const layers = outputStage.children
      const outputLayer = layers[3]
      const canv = outputLayer.getCanvas() 
      download(canv.toDataURL("image/jpeg"), selectedAfterImageData.fileName)
      dispatch(roomDesignActions.setIsSaveChangesPhotoPopupShow(false))
      dispatch(roomDesignActions.setIsEditPhotoPopupShow(false))
    }, 100)
  }

  return (
    <>
      <Modal show={isSaveChangesPhotoPopupShow} onClose={closePopup} size="md">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <button type="button" onClick={closePopup}
                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
                <CloseModalIcon />
                <span className="sr-only">閉じる</span>
              </button>
              <div className="p-4 md:p-5">
                <LeadPencilIcon />
                <h3 className="text-base leading-6 text-gray-900">編集内容を保存し、画像をダウンロードしますか?<br/>
                保存後は元の画像に戻すことはできません。</h3>
                <div className="flex flex-row-reverse items-center mt-6 space-x-4">
                <button type="button" onClick={downloadPhoto}
                    className="sm:ml-3 w-28 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">OK</button>
                  <button type="button" onClick={closePopup}
                    className="sm:ml-3 w-28 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100">キャンセル</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(RoomDesignSaveChangesConfirm)
