import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import {SortAscIcon, SortDescIcon, NoSortIcon} from './SVGIcons'
import {usersListActions} from '../reducers/usersList'
import {filterUserActions} from '../reducers/filterUser'
import {deleteUserActions} from '../reducers/deleteUser'
import {formatDate, pad5} from '../libs'
import UserListActionBar from './UserListActionBar'
import {isExpiredUser} from '../libs/index'
import {RealEstateType} from '../constants/index'
function mapStateToProps(state: any) {
  return { ...state.usersList }
}

const UserList = (props: any) => {
  const {items, page, pageCount, contractStartDateSort, contractEndDateSort} = props
  const navigate = useNavigate()
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  useEffect(() => {
    dispatch(usersListActions.fetchUsers())
    dispatch(filterUserActions.getFilters())
  }, []);
  
  let showPages = [page]
  if (page > 1) 
    showPages.push(page-1)
  if (page < pageCount) 
    showPages.push(page+1)
  showPages.sort()

  const setFirstPage = () => {
    if (page == 1) return
    dispatch(usersListActions.setPageAndFetch(1))
  }
  const setPreviousPage = () => {
    if (page == 1) return
    dispatch(usersListActions.setPageAndFetch(page-1))
  }
  const setPage = (toPage: number) => {
    if (page == toPage) return
    dispatch(usersListActions.setPageAndFetch(toPage))
  }
  const setNextPage = () => {
    if (page == pageCount) return
    dispatch(usersListActions.setPageAndFetch(page+1))
  }
  const setLastPage = () => {
    if (page == pageCount) return
    dispatch(usersListActions.setPageAndFetch(pageCount))
  }

  const switchSortContractStartDate = () => {
    dispatch(usersListActions.switchSortContractStartDate())
  }
  const switchSortContractEndDate = () => {
    dispatch(usersListActions.switchSortContractEndDate())
  }

  const goToViewUser = (id: number) => {
    navigate("/user-view/" + id)
  }
  const goToEditUser = (id: number) => {
    navigate("/user-edit/" + id)
  }
  const showConfirmDeleteUser = (user: any) => {
    const {id, email, is_active} = user
    if (!is_active) return
    
    dispatch(deleteUserActions.setIsPopupShow(true))
    dispatch(deleteUserActions.setId(id))
    dispatch(deleteUserActions.setEmail(email))
  }
  const RealEstateTypeFlip = Object.fromEntries(Object.entries(RealEstateType).map(([key, value]) => [value, key]));
  return (
    <>
      <UserListActionBar />

      <div className="max-w-8xl w-full overflow-x-auto mx-auto text-[#2d2d2d] userlist-wrapper">
        <table className="min-w-full border-collapse border border-gray-500 py-3 px-4">
          <colgroup>
            <col className="w-16"/>
            <col className="w-36"/>
            <col className="w-36"/>
            <col className="w-36"/>
            <col className="w-60"/>
            <col/>
            <col/>
            <col/>
            <col/>
            <col/>
            <col className="w-60"/>
            <col/>
            <col className="w-40"/>
          </colgroup>
          <thead className="bg-[#5571ad] sticky top-0 z-10">
            <tr>
              <th className="px-3 py-2 text-center text-white border border-[#889bc5] whitespace-nowrap">ID</th>
              <th className="px-3 py-2 text-center text-white border border-[#889bc5] whitespace-nowrap w-36">法人名 </th>
              <th className="px-3 py-2 text-center text-white border border-[#889bc5] whitespace-nowrap">店舗名</th>
              <th className="px-3 py-2 text-center text-white border border-[#889bc5] whitespace-nowrap">ユーザーID</th>
              <th className="px-3 py-2 text-center text-white border border-[#889bc5] whitespace-nowrap">メールアドレス</th>
              <th  className="px-3 py-2 text-center text-white border border-[#889bc5] whitespace-nowrap">不動産種別</th>
              <th className="px-3 py-2 text-center text-white border border-[#889bc5] whitespace-nowrap">利用プラン</th>
              <th className="px-3 py-2 text-center text-white border border-[#889bc5] whitespace-nowrap">発行回数/上限</th>
              <th className="px-3 py-2 text-center text-white border border-[#889bc5] whitespace-nowrap">
                <div className="flex gap-1 items-center w-full">
                  <span className="flex-1">契約開始日</span>
                  {contractStartDateSort == 'none' && (
                    <NoSortIcon onClick={switchSortContractStartDate} />
                  )}
                  {contractStartDateSort == 'asc' && (
                    <SortAscIcon onClick={switchSortContractStartDate} />
                  )}
                  {contractStartDateSort == 'desc' && (
                    <SortDescIcon onClick={switchSortContractStartDate} />
                  )}
                </div>
              </th>
              <th className="px-3 py-2 text-center text-white border border-[#889bc5] whitespace-nowrap">
                <div className="flex gap-1 items-center w-full">
                  <span className="flex-1">契約終了日</span>
                    {contractEndDateSort == 'none' && (
                    <NoSortIcon onClick={switchSortContractEndDate} />
                  )}
                  {contractEndDateSort == 'asc' && (
                    <SortAscIcon onClick={switchSortContractEndDate} />
                  )}
                  {contractEndDateSort == 'desc' && (
                    <SortDescIcon onClick={switchSortContractEndDate} />
                  )}
                </div>
              </th>
              <th className="px-3 py-2 text-center text-white border border-[#889bc5] whitespace-nowrap">備考</th>
              <th className="px-3 py-2 text-center text-white border border-[#889bc5] whitespace-nowrap">ステータス</th>
              <th className="sticky top-0 right-0 px-3 py-2 text-center text-white bg-[#5571ad] border border-[#889bc5] whitespace-nowrap">管理</th>
            </tr>
          </thead>
          <tbody className="bg-white text-sm">
          {items.map((item: any) => (
            <tr key={item.id} className="hover:bg-sky-50">
              <td className="p-2 border border-gray-300 text-center">{pad5(item.id)}</td>
              <td className="p-2 border border-gray-300 whitespace-nowrap">
                {item.account ? item.account.corporate_name : ''}</td>
              <td className="p-2 border border-gray-300">{item.account ? item.account.store_name : ''}</td>
              <td className="p-2 border border-gray-300">{item.user_name}</td>
              <td className="p-2 border border-gray-300">{item.email}</td>
              <td className="p-2 border border-gray-300 text-center">{item.account ? RealEstateTypeFlip[item.account.real_estate_type] : ''}</td>
              <td className="p-2 border border-gray-300 text-center">{item.account ? item.account.plan_type.name : ''}</td>
              <td className="p-2 border border-gray-300 text-center">
                {item.account ? item.account.credit_consumed : ''}/{item.account ? item.account.credit_limit : ''}</td>
              <td className="p-2 border border-gray-300 text-center">
                {item.account ? formatDate(item.account.contract_start_date) : ''}</td>
              <td className="p-2 border border-gray-300 text-center">
                {item.account ? formatDate(item.account.contract_end_date) : ''}</td>
              <td className="p-2 border border-gray-300">{item.account ? item.account.remarks : ''}</td>
              <td className="p-2 border border-gray-300 text-center">{!isExpiredUser(item.account) ? '利用中' : '停止'}</td>
              <td className="sticky right-0 bg-white p-2 border border-gray-300">
                <div className="flex items-center justify-center space-x-2">
                  <button onClick={() => goToViewUser(item.id)}
                    className="px-2 py-1 text-xs font-medium rounded border border-blue-700 text-white bg-blue-700 hover:bg-blue-600 whitespace-nowrap">詳細</button>
                  <button onClick={() => goToEditUser(item.id)}
                    className="px-2 py-1 text-xs font-medium rounded border border-blue-700 text-blue-700 bg-white hover:text-white hover:bg-blue-700 whitespace-nowrap">編集</button>
                  <button onClick={() => showConfirmDeleteUser(item)}
                    className="px-2 py-1 text-xs font-medium rounded border border-red-700 text-red-600 bg-white hover:text-white hover:bg-red-700 whitespace-nowrap">削除</button>
                </div>
              </td>
            </tr>
          ))}

          </tbody>
        </table>
      </div>

      <nav className="flex justify-center py-2">
        <ol className="inline-flex -space-x-px text-base h-10">
          <li>
            <a href="#" onClick={setFirstPage}
              className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"><span className="hidden md:inline-flex">最初のページ</span><span className="md:hidden inline-flex">&laquo;</span></a>
          </li>
          <li>
            <a href="#" onClick={setPreviousPage}
              className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"><span className="hidden md:inline-flex">前へ</span><span className="md:hidden inline-flex">&lt;</span></a>
          </li>
          {showPages.map(showPage => (
            <li key={showPage}>
              <a href="#" onClick={() => {setPage(showPage)}}
                className={page == showPage 
                  ? "flex items-center justify-center px-4 h-10 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700" 
                  : "flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"}>
                  {showPage}</a>
            </li> 
          ))}
          <li>
            <a href="#" onClick={setNextPage}
              className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"><span className="hidden md:inline-flex">次へ</span><span className="md:hidden inline-flex">&gt;</span></a>
          </li>
          <li>
            <a href="#" onClick={setLastPage}
              className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"><span className="hidden md:inline-flex">最後のページ</span><span className="md:hidden inline-flex">&raquo;</span></a>
          </li>
        </ol>
      </nav>
    </>
  );
};

export default connect(mapStateToProps)(UserList)
