import { useEffect } from "react"
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch } from "react-redux"
import {useParams, useNavigate} from 'react-router-dom'
import {PlanType, RealEstateType, PREmailAvailType} from "../constants"
import {getKeyByValue, formatDate} from '../libs'

function mapStateToProps(state: any) {
  return { ...state.editingUser }
}

const UserViewDetail = (props: any) => {
  const {
    id,
    email,
    userName,
    planType,
    creditLimit,
    corporateName,
    storeName,
    picName,
    postCode,
    address,
    contractStart,
    contractEnd,
    prEmailAvail,
    registrationRoute,
    marketingChannel,
    remarks,
    realEstateType,
  } = props

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  const cancel = () => {
    navigate("/")
  }
  const goToEditUser = () => {
    navigate("/user-edit/" + id)
  }

  return (
    <div className="container mx-auto my-3 pb-2 bg-white rounded-lg text-[#2d2d2d]">
      <h1 className="text-xl rounded-t-lg px-3 py-2 bg-[#5571ad] text-white"><b>詳細画面</b></h1>
      <table className="min-w-full border-collapse border border-gray-500 bg-white">
        <colgroup>
          <col className="max-w-80 w-80"/>
          <col/>
        </colgroup>
        <tbody>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">法人名</th>
          <td className="px-3 py-2 border border-gray-300">{corporateName}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">店舗名</th>
          <td className="px-3 py-2 border border-gray-300">{storeName}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">担当者氏名</th>
          <td className="px-3 py-2 border border-gray-300">{picName}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">郵便番号</th>
          <td className="px-3 py-2 border border-gray-300">{postCode}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">住所</th>
          <td className="px-3 py-2 border border-gray-300">{address}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">ユーザーID</th>
          <td className="px-3 py-2 border border-gray-300">{userName}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">パスワード</th>
          <td className="px-3 py-2 border border-gray-300">••••••••</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">メールアドレス</th>
          <td className="px-3 py-2 border border-gray-300">{email}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">不動産種別</th>
          <td className="px-3 py-2 border border-gray-300">{getKeyByValue(RealEstateType, realEstateType)}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">利用プラン</th>
          <td className="px-3 py-2 border border-gray-300">{getKeyByValue(PlanType, planType)}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">上限回数</th>
          <td className="px-3 py-2 border border-gray-300">{creditLimit}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">契約開始日</th>
          <td className="px-3 py-2 border border-gray-300">{formatDate(contractStart)}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">契約終了日</th>
          <td className="px-3 py-2 border border-gray-300">{formatDate(contractEnd)}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">販促メール</th>
          <td className="px-3 py-2 border border-gray-300">{getKeyByValue(PREmailAvailType, prEmailAvail)}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">登録経路</th>
          <td className="px-3 py-2 border border-gray-300">{registrationRoute}</td>
        </tr>
        <tr>
          <th className="bg-[#778ebe] border border-gray-200 px-3 py-2 text-white w-80">備考</th>
          <td className="px-3 py-2 border border-gray-300">{remarks}</td>
        </tr>
        </tbody>
      </table>
      <div className="flex items-center justify-end gap-2 gap-2 p-3 text-center">
        <button onClick={cancel} className="py-2.5 px-5 text-sm font-medium focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 min-w-28">戻る</button>
        <button onClick={goToEditUser} className="py-2.5 px-5 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 hover:text-white whitespace-nowrap min-w-28">編集</button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(UserViewDetail)
