import { useEffect, useRef, useState } from "react"
import {ThunkDispatch} from "@reduxjs/toolkit";
import {connect, useDispatch, useSelector } from "react-redux"
import {useNavigate} from 'react-router-dom'
import {editPhotoActions} from '../../reducers/editPhoto'
import { Modal } from "flowbite-react";
import {CircleOutlineIcon, FastenIcon, SquareOutlineIcon, TriangleOutlineIcon, UndoIcon} from '../SVGIcons'
import {selectAfterImageData} from '../../selectors'
import {roomDesignActions} from '../../reducers/roomDesign'
import { Slider } from 'primereact/slider';
import Drawing from './Drawing'

type EraserShape = "circle" | "triangle" | "square"

function mapStateToProps(state: any) {
  const {selectedAfterImageData} = selectAfterImageData(state);
  return { ...state.roomDesign,
    selectedAfterImageData
   }
}

const RoomDesignEditPhotoPopup = (props: any) => {
  const {curHead, curHeadSize, lines}  = useSelector((state: any) => state.editPhoto)
  const {
    isEditPhotoPopupShow,
  } = props
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(editPhotoActions.setInitState())
  }, [isEditPhotoPopupShow])

  const closePopup = () => {
    dispatch(roomDesignActions.setIsEditPhotoPopupShow(false))
  }
  const handleSave = () => {
    dispatch(roomDesignActions.setIsSaveChangesPhotoPopupShow(true))
  }
  const handleDiscard = () => {
    dispatch(roomDesignActions.setIsDiscardChangesPhotoPopupShow(true))
  }
  const resetEdit = () => {
    dispatch(editPhotoActions.setLines([]))
  }

  const setDefaultCurHead = (shape:EraserShape) => {
    // dispatch(editPhotoActions.setDefaultCurHead(shape))
  }

  const renderEraserShape = (size:number) => {
    switch(curHead) {
      case 'circle':
        return <CircleOutlineIcon style={{stroke: "#595959", strokeWidth: "2px", width: `${size}px`, height: `${size}px`}}/>;
      case 'triangle':
        return <TriangleOutlineIcon style={{stroke: "#595959", strokeWidth: '2px', width: `${size}px`, height: `${size}px`}} />;
      case 'square':
        return <SquareOutlineIcon style={{stroke: "#595959", strokeWidth: '2px', width: `${size}px`, height: `${size}px`}} />;
      default:
        return;
    }
  }

  return (
    <>
      <Modal show={isEditPhotoPopupShow} onClose={closePopup} size="xl">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
              <div className="py-2 px-4 bg-orange-500 text-white">
                <p>• 本画面ではAIが生成した家具を部分的に消すことが可能です。</p>
                <p>• 削除したい箇所をクリックしながら、マウスを移動させてください。</p>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    {/* <div className="flex items-center py-1 px-2 border-r border-[#b9b7b8]">
                      <div
                        onClick={() => setDefaultCurHead("circle")}
                        className="p-1 border-r border-[#f1f1f1] hover:cursor-pointer"
                      >
                        <CircleOutlineIcon
                          style={{
                            stroke: curHead === "circle" ? "#595959" : "#d7d7d7",
                            strokeWidth: "3px",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </div>
                      <div
                        onClick={() => setDefaultCurHead("triangle")}
                        className="p-1 border-r border-[#f1f1f1] hover:cursor-pointer"
                      >
                        <TriangleOutlineIcon
                          style={{
                            stroke: curHead === "triangle" ? "#595959" : "#d7d7d7",
                            strokeWidth: "3px",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </div>
                      <div
                        onClick={() => setDefaultCurHead("square")}
                        className="p-1 hover:cursor-pointer"
                      >
                        <SquareOutlineIcon
                          style={{
                            stroke: curHead === "square" ? "#595959" : "#d7d7d7",
                            strokeWidth: "3px",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </div>
                    </div> */}
                    <div className="flex items-center py-1 px-2 border-r border-[#b9b7b8] gap-2">
                      <div>
                        {renderEraserShape(16)}
                      </div>
                      <div>
                        <Slider
                          value={curHeadSize}
                          onChange={(e) => {
                            dispatch(editPhotoActions.setCurHeadSize(e.value))
                          }}
                        />
                      </div>
                      <div>
                      {renderEraserShape(32)}
                      </div>
                    </div>
                    {/* <div className="flex items-center py-1 px-2 border-r border-[#b9b7b8] gap-2">
                      <div className="p-1 hover:cursor-pointer">
                        <UndoIcon style={{strokeWidth: '1px', width: '24px', height: '24px'}} />
                      </div>
                      <div className="p-1 hover:cursor-pointer">
                        <FastenIcon style={{ stroke: '#d7d7d7', strokeWidth: '1px', width: '24px', height: '24px'}} />
                      </div>
                    </div> */}
                    <div className="flex items-center py-1 px-2">
                      <button disabled={lines.length == 0} onClick={resetEdit} className="disabled:text-[#d1d1d1] px-4 py-1 mr-3 font-medium">
                        やり直す
                      </button>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <button onClick={handleSave} className="px-4 py-1 mr-3 font-medium">
                      保存
                    </button>
                    <div className="w-[1px] h-[40px] bg-[#b9b7b8] mr-3"></div>
                    <button onClick={handleDiscard} className="px-4 py-1 font-medium">
                      閉じる
                    </button>
                  </div>
                </div>
                <Drawing />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(RoomDesignEditPhotoPopup)
