import * as Sentry from "@sentry/react";
import cookie from "cookie";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux'
import { useEffect, useState } from "react";
import {ThunkDispatch} from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import {connect, useDispatch } from "react-redux"
import {currentUserActions} from './reducers/currentUser'

import { OpenAPI } from "./api";
import store from "./store"
import MultiTabError from "./components/MultiTabError"
import ProtectedPage from "./components/ProtectedPage";
import Home from "./components/Home";
import Login from "./components/Login";
import UserCreate from './components/UserCreate'
import UserEdit from "./components/UserEdit"
import UserView from './components/UserView'

OpenAPI.interceptors.request.use((request) => {
  const { csrftoken } = cookie.parse(document.cookie);
  if (request.headers && csrftoken) {
    request.headers["X-CSRFTOKEN"] = csrftoken;
  }
  return request;
});

const AppRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const [isCurrentTab, setIsCurrentTab] = useState(true);
  // const logOut = () => {dispatch(currentUserActions.signOutUser(navigate))}
  useEffect(() => {
    // const channel = new BroadcastChannel('tab');
    // channel.postMessage('first-tab');
    // const msgProcess = (msg: any) => {
    //   if (msg.data === 'first-tab') {
    //     channel.postMessage('please-close-tab');
    //     return
    //   }
    //   if (msg.data === 'please-close-tab') {
    //     channel.removeEventListener('message', msgProcess)
    //     // window.removeEventListener("beforeunload", logOut);
    //     setIsCurrentTab(false)
    //     return
    //   }  
    // }
    
    // channel.addEventListener('message', msgProcess);
    // // window.addEventListener("beforeunload", logOut);
  }, []);
  if (!isCurrentTab) 
    return (<MultiTabError />)

  return (
    <>
      <Routes>
        <Route path="login" element={<Login />} />
      </Routes>
      <Routes>
        <Route path="" element={<ProtectedPage><Home /></ProtectedPage>} />
      </Routes>
      <Routes>
        <Route path="user-create" element={<ProtectedPage><UserCreate /></ProtectedPage>} />
      </Routes>
      <Routes>
        <Route path="user-edit/:id" element={<ProtectedPage><UserEdit /></ProtectedPage>} />
      </Routes>
      <Routes>
        <Route path="user-view/:id" element={<ProtectedPage><UserView /></ProtectedPage>} />
      </Routes>
    </>
  )
}

const App = () => {

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Provider store={store}>
        <HashRouter>
          <AppRoutes />
        </HashRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  )
}

export default App;
