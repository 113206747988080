import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {toastActions} from './toast'
import {getRandId, readURIFromFile} from '../libs'
import {RoomDesignService} from '../api'
import {currentUserActions} from './currentUser'
import {selectBeforeImageData} from '../selectors'
import {MaxPhotoUpload, MaxUploadFileSize, MaxRegenCount} from '../constants'

interface BeforeImage {
    id?: number,
    stubId: number,
    roomType: string,
    lifestyle: string,
    hasTatami: boolean,
    file: File,
    uri: string,
    serverURI: string,
    isGeneratedFail: false,
    maxRegenCount: number,
}

interface AfterImage {
    id: number,
    before_image_id: number,
    serverURI: string,
    fileName: string,
}

const addBeforeImages = createAsyncThunk(
    'addBeforeImages',
    async (files: FileList, thunkAPI: any) => {
        const {beforeImages} = thunkAPI.getState().roomDesign
        let newImages = [...beforeImages]
        for (const file of files) {
            if (file.type != 'image/png' && file.type != 'image/jpeg' && file.type != 'image/webp') continue
            if (file.size >= MaxUploadFileSize) {
                thunkAPI.dispatch(roomDesignActions.setIsPhotoTooBigPopupShow(true))
                continue
            }
            if (newImages.length >= MaxPhotoUpload) break
            const uri = await readURIFromFile(file)
            newImages.push({
                stubId: getRandId(),
                file,
                uri,
                hasTatami: false,
                maxRegenCount: MaxRegenCount,
            })
        }
        thunkAPI.dispatch(roomDesignActions.setBeforeImages(newImages))
    }
)

const setRoomType = createAsyncThunk(
    'setRoomType',
    async (roomType: string, thunkAPI: any) => {
        const {beforeImages} = thunkAPI.getState().roomDesign
        const selectedBeforeImageData = selectBeforeImageData(thunkAPI.getState())
        const newImages = beforeImages.map((image: BeforeImage) => {
            if (image.stubId != selectedBeforeImageData.stubId) return image
            return {
                ...image,
                roomType,
            }
        })
        thunkAPI.dispatch(roomDesignActions.setBeforeImages(newImages))
    }
)

const setLifestyle = createAsyncThunk(
    'setLifestyle',
    async (lifestyle: string, thunkAPI: any) => {
        const {beforeImages} = thunkAPI.getState().roomDesign
        const selectedBeforeImageData = selectBeforeImageData(thunkAPI.getState())
        const newImages = beforeImages.map((image: BeforeImage) => {
            if (image.stubId != selectedBeforeImageData.stubId) return image
            return {
                ...image,
                lifestyle,
            }
        })
        thunkAPI.dispatch(roomDesignActions.setBeforeImages(newImages))
    }
)

const setHasTatami = createAsyncThunk(
    'setHasTatami',
    async (hasTatami: boolean, thunkAPI: any) => {
        const {beforeImages} = thunkAPI.getState().roomDesign
        const selectedBeforeImageData = selectBeforeImageData(thunkAPI.getState())
        const newImages = beforeImages.map((image: BeforeImage) => {
            if (image.stubId != selectedBeforeImageData.stubId) return image
            return {
                ...image,
                hasTatami,
            }
        })
        thunkAPI.dispatch(roomDesignActions.setBeforeImages(newImages))
    }
)

const createRoomDesign = createAsyncThunk(
    'createRoomDesign',
    async (navigate: any, thunkAPI: any) => {
        const {
            stubId,
            beforeImages, 
        } = thunkAPI.getState().roomDesign
        thunkAPI.dispatch(roomDesignActions.setIsLoading(true))
        let runTime = 0
        thunkAPI.dispatch(roomDesignActions.setRuntime(runTime))
        const timer = setInterval(() => {thunkAPI.dispatch(roomDesignActions.setRuntime(runTime++))}, 1000)
        try {
            await beforeImagesGetAfterImages(thunkAPI, beforeImages, stubId)
            thunkAPI.dispatch(roomDesignActions.setIsLoading(false))
        } catch (e) {}
        clearInterval(timer)
        thunkAPI.dispatch(currentUserActions.getCurrentUser(navigate))
    }
)

function beforeImagesGetAfterImages(thunkAPI: any, beforeImages: BeforeImage[], stubId: any) {
    const promises = beforeImages.map(beforeImage => 
        beforeImageGetAfterImages(beforeImage, thunkAPI, stubId)
    )
    return Promise.all(promises)
}

function beforeImageGetAfterImages(beforeImage: BeforeImage, thunkAPI: any, stubId: any) {
    return new Promise(async (resolve, reject) => {
        const {roomType, lifestyle, hasTatami, file} = beforeImage
        const res = await RoomDesignService.firstgenAfterImagen({formData:{
            stub_id: stubId, room_type: roomType, has_tatami: hasTatami, lifestyle, before_image: file}})
        .then(res => {
            return processServerAfterImages(beforeImage.stubId, res, thunkAPI)
        })
        .catch(res => {
            return processServerAfterImages(beforeImage.stubId, res, thunkAPI)
        })
        if (!res || !res.id) {
            resolve(false)
            return
        }
        for (let i = 1; i <=2; i++) {
            await getMoreAfterImage(beforeImage.stubId, res, thunkAPI)
        }
        resolve(true)
    })
}

function processServerAfterImages(stubId: any, res: any, thunkAPI: any) {
    const {beforeImages, afterImages} = thunkAPI.getState().roomDesign
    const newBeforeImages = beforeImages.map((beforeImage: any) => {
        if (beforeImage.stubId != stubId) return beforeImage
        if (!res.id) {
            if (res.message != "cannot_generate_photo")
                thunkAPI.dispatch(toastActions.toastError(res.message))
            return {
                ...beforeImage,
                isGeneratedFail: true,
            }
        }

        return {
            ...beforeImage,
            isGeneratedFail: false,
            id: res.id,
            serverURI: res.file,
        }
    })
    thunkAPI.dispatch(roomDesignActions.setBeforeImages(newBeforeImages))
    if (!res.id) return res

    let newAfterImages = afterImages.filter((afterImage: AfterImage) => afterImage.before_image_id != res.id)
    res.afterimages.forEach((afterimage: any) => {
        newAfterImages.push({
            id: afterimage.id,
            before_image_id: res.id,
            serverURI: afterimage.downloadurl,
            fileName: afterimage.file_name,
        })
    })
    thunkAPI.dispatch(roomDesignActions.setAfterImages(newAfterImages))
    return res
}

function getMoreAfterImage(stubId: any, res: any, thunkAPI: any) {
    if (!res.id) return null
    return RoomDesignService.regenAfterImages({id: res.id})
        .then(res => {
            processServerAfterImages(stubId, res, thunkAPI)
        })
        .catch(res => {
            processServerAfterImages(stubId, res, thunkAPI)
        })
}

const regenAfterImages = createAsyncThunk(
    'regenAfterImages',
    async (navigate: any, thunkAPI: any) => {
        const selectedBeforeImageData = selectBeforeImageData(thunkAPI.getState())
        thunkAPI.dispatch(roomDesignActions.setIsLoading(true))
        thunkAPI.dispatch(roomDesignActions.subtractMaxRegen(selectedBeforeImageData.stubId))
        thunkAPI.dispatch(roomDesignActions.setSelectedRegenImage(selectedBeforeImageData.stubId))
        let runTime = 0
        thunkAPI.dispatch(roomDesignActions.setRuntime(runTime))
        const timer = setInterval(() => {thunkAPI.dispatch(roomDesignActions.setRuntime(runTime++))}, 1000)
        try {
            await beforeImageRegenAfterImages(selectedBeforeImageData, thunkAPI)
            thunkAPI.dispatch(roomDesignActions.setIsLoading(false))
            thunkAPI.dispatch(roomDesignActions.setSelectedRegenImage(undefined))
        } catch (e) {}
        clearInterval(timer)
        thunkAPI.dispatch(currentUserActions.getCurrentUser(navigate))
    }
)

function beforeImageRegenAfterImages(beforeImage: BeforeImage, thunkAPI: any) {
    return new Promise(async (resolve, reject) => {
        if (!beforeImage.id) {
            resolve(false)
            return
        }
        const res = await RoomDesignService.clearAfterImages({id: beforeImage.id})
        .then(res => {
            return processServerAfterImages(beforeImage.stubId, res, thunkAPI)
        })
        .catch(res => {
            return processServerAfterImages(beforeImage.stubId, res, thunkAPI)
        })
        if (!res || !res.id) {
            resolve(false)
            return
        }
        for (let i = 1; i <=3; i++) {
            await getMoreAfterImage(beforeImage.stubId, res, thunkAPI)
        }
        resolve(true)
    })
}

const initialState = {
    isLoading: false,
    stubId: getRandId(),
    selectedBeforeImage: undefined,
    selectedRegenImage: undefined,
    selectedAfterImage: undefined,
    beforeImages: [] as BeforeImage[],
    afterImages: [] as AfterImage[],
    isConfirmPopupShow: false,
    isRoomTypePopupShow: false,
    isLifestylePopupShow: false,
    isClearAllPopupShow: false,
    isGeneratedFail: false,
    isRegenPopupShow: false,
    isPhotoTooBigPopupShow: false,
    maxExecTimePerImage: 75, //seconds
    runTime: 0,
    isEditPhotoPopupShow: false,
    isSaveChangesPhotoPopupShow: false,
    isDiscardChangesPhotoPopupShow: false,
    beforeImageRef: undefined as any,
    afterImageRef: undefined as any,
}

const roomDesignSlice = createSlice({
    name: 'roomDesign',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setBeforeImages: (state, action) => {
            state.beforeImages = action.payload
        },
        setSelectedBeforeImage: (state, action) => {
            state.selectedBeforeImage = action.payload
        },
        setSelectedRegenImage: (state, action) => {
            state.selectedRegenImage = action.payload
        },
        removeBeforeImage: (state, action) => {
            const removeStubId = action.payload
            state.beforeImages = state.beforeImages.filter(beforeImage => beforeImage.stubId != removeStubId)
        },
        setAfterImages: (state, action) => {
            state.afterImages = action.payload
        },
        setSelectedAfterImage: (state, action) => {
            state.selectedAfterImage = action.payload
        },
        setIsConfirmPopupShow: (state, action) => {
            state.isConfirmPopupShow = action.payload
        },
        setIsRoomTypePopupShow: (state, action) => {
            state.isRoomTypePopupShow = action.payload
        },
        setIsLifestylePopupShow: (state, action) => {
            state.isLifestylePopupShow = action.payload
        },
        setIsClearAllPopupShow: (state, action) => {
            state.isClearAllPopupShow = action.payload
        },
        setIsGeneratedFail: (state, action) => {
            state.isGeneratedFail = action.payload
        },
        setIsRegenPopupShow: (state, action) => {
            state.isRegenPopupShow = action.payload
        },
        setIsPhotoTooBigPopupShow: (state, action) => {
            state.isPhotoTooBigPopupShow = action.payload
        },
        setRuntime: (state, action) => {
            state.runTime = action.payload
        },
        subtractMaxRegen: (state, action) => {
            const stubId = action.payload
            state.beforeImages.forEach(image => {
                if (image.stubId != stubId) return
                image.maxRegenCount = image.maxRegenCount-1
            })
        },
        setIsEditPhotoPopupShow: (state, action) => {
            state.isEditPhotoPopupShow = action.payload
        },
        setIsSaveChangesPhotoPopupShow: (state, action) => {
            state.isSaveChangesPhotoPopupShow = action.payload
        },
        setIsDiscardChangesPhotoPopupShow: (state, action) => {
            state.isDiscardChangesPhotoPopupShow = action.payload
        },
        setBeforeImageRef: (state, action) => {
            state.beforeImageRef = action.payload
        },
        setAfterImageRef: (state, action) => {
            state.afterImageRef = action.payload
        },
        setInitState: (state) => {
            return initialState
        },
    },
})

const roomDesignActions = {
    ...roomDesignSlice.actions,
    addBeforeImages,
    setRoomType,
    setLifestyle,
    setHasTatami,
    createRoomDesign,
    regenAfterImages,
}
export {roomDesignActions}
export default roomDesignSlice.reducer